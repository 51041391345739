<template>
  <b-tabs content-class="">
    <b-tab
      v-for="(option, index) in userSettingsTabsLis"
      :key="index"
      @click="goTo(option.to)"
      :title-item-class="'custom-tab-outer-li'"
      :active="option.currentTab == $route.name ? true : false"
    >
      <template #title>
        <span v-b-popover.hover.bottomright="`${option.itemName}`">
          <img :src="`${option.itemImagePath}`" class="icon-tab" />
          <span>{{ option.itemName }}</span>
        </span>
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
import imgMasterAdmins from "@/assets/images/masterAdmins.svg";
import imgEmployees from "@/assets/images/employees.svg";
import imgStudents from "@/assets/images/students.svg";
import imgParents from "@/assets/images/parents.svg";
import imgEstablishmentsRole from "@/assets/images/establishment-role.svg";
import imgLoginCode from "@/assets/images/UserLoginCodes.svg";

import { checkPrivilege } from "./../../utils/functions";
import { USER_TYPE } from "./../../utils/constantLists";

import {
  hasMasterAdmin,
  hasMasterAdminEstablishmentsRole,
  hasMasterAdminLoginCode,
  hasEmployee,
  hasEmployeeEstablishmentsRole,
  hasEmployeeLoginCode,
  hasStudent,
  hasStudentEstablishmentsRole,
  hasStudentLoginCode,
  hasParent,
  hasParentEstablishmentsRole,
  hasParentLoginCode,
} from "./../../utils/privilegeHelper";

export default {
  name: "UserSettingsTabs",
  props: {
    userTypeToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userTypeTokenData: this.userTypeToken || this.$route.meta.userTypeToken,
    };
  },
  computed: {
    userSettingsTabsLis() {
      let tabs = [];

      switch (this.userTypeTokenData) {
        case USER_TYPE.MasterAdmin:
          tabs = this.userSettingsTabsListMasterAdmin;
          break;
        case USER_TYPE.Employee:
          tabs = this.userSettingsTabsListEmployee;
          break;
        case USER_TYPE.Student:
          tabs = this.userSettingsTabsListStudent;
          break;
        case USER_TYPE.Supplier:
          tabs = this.userSettingsTabsListSupplier;
          break;
        case USER_TYPE.Parent:
          tabs = this.userSettingsTabsListParent;
          break;
        case USER_TYPE.Client:
          tabs = this.userSettingsTabsListClient;
          break;
      }

      return tabs;
    },
    userSettingsTabsListMasterAdmin: function () {
      let tabs = [];
      if (checkPrivilege(hasMasterAdmin())) {
        tabs.push({
          to: {
            name: "MasterAdmin",
          },
          itemName: this.$t("masterAdmins.modelName"),
          itemImagePath: imgMasterAdmins,
          currentTab: "MasterAdmin",
        });
      }
      if (checkPrivilege(hasMasterAdminEstablishmentsRole())) {
        tabs.push({
          to: {
            name: "MasterAdminEstablishmentsRole",
          },
          itemName: this.$t("UserSettings.roles"),
          itemImagePath: imgEstablishmentsRole,
          currentTab: "MasterAdminEstablishmentsRole",
        });
      }
      if (checkPrivilege(hasMasterAdminLoginCode())) {
        tabs.push({
          to: {
            name: "MasterAdminLoginCode",
          },
          itemName: this.$t("UserSettings.loginCode"),
          itemImagePath: imgLoginCode,
          currentTab: "MasterAdminLoginCode",
        });
      }

      return tabs;
    },
    userSettingsTabsListEmployee: function () {
      let tabs = [];
      if (checkPrivilege(hasEmployee())) {
        tabs.push({
          to: {
            name: "Employees",
          },
          itemName: this.$t("employees.modelName"),
          itemImagePath: imgEmployees,
          currentTab: "Employee",
        });
      }
      if (checkPrivilege(hasEmployeeEstablishmentsRole())) {
        tabs.push({
          to: {
            name: "EmployeeEstablishmentsRole",
          },
          itemName: this.$t("UserSettings.roles"),
          itemImagePath: imgEstablishmentsRole,
          currentTab: "EmployeeEstablishmentsRole",
        });
      }
      if (checkPrivilege(hasEmployeeLoginCode())) {
        tabs.push({
          to: {
            name: "EmployeeLoginCode",
          },
          itemName: this.$t("UserSettings.loginCode"),
          itemImagePath: imgLoginCode,
          currentTab: "EmployeeLoginCode",
        });
      }

      return tabs;
    },
    userSettingsTabsListStudent: function () {
      let tabs = [];
      if (checkPrivilege(hasStudent())) {
        tabs.push({
          to: {
            name: "Students",
          },
          itemName: this.$t("students.modelName"),
          itemImagePath: imgStudents,
          currentTab: "Student",
        });
      }
      if (checkPrivilege(hasStudentEstablishmentsRole())) {
        tabs.push({
          to: {
            name: "StudentEstablishmentsRole",
          },
          itemName: this.$t("UserSettings.roles"),
          itemImagePath: imgEstablishmentsRole,
          currentTab: "StudentEstablishmentsRole",
        });
      }
      if (checkPrivilege(hasStudentLoginCode())) {
        tabs.push({
          to: {
            name: "StudentLoginCode",
          },
          itemName: this.$t("UserSettings.loginCode"),
          itemImagePath: imgLoginCode,
          currentTab: "StudentLoginCode",
        });
      }

      return tabs;
    },

    userSettingsTabsListParent: function () {
      let tabs = [];
      if (checkPrivilege(hasParent())) {
        tabs.push({
          to: {
            name: "Parents",
          },
          itemName: this.$t("parents.modelName"),
          itemImagePath: imgParents,
          currentTab: "Parent",
        });
      }
      if (checkPrivilege(hasParentEstablishmentsRole())) {
        tabs.push({
          to: {
            name: "ParentEstablishmentsRole",
          },
          itemName: this.$t("UserSettings.roles"),
          itemImagePath: imgEstablishmentsRole,
          currentTab: "ParentEstablishmentsRole",
        });
      }
      if (checkPrivilege(hasParentLoginCode())) {
        tabs.push({
          to: {
            name: "ParentLoginCode",
          },
          itemName: this.$t("UserSettings.loginCode"),
          itemImagePath: imgLoginCode,
          currentTab: "ParentLoginCode",
        });
      }

      return tabs;
    },
  },
  watch: {},
  methods: {
    goTo(path) {
      this.$router.push(path).catch(() => {});
    },
  },

  async created() {},
};
</script>
